// obtain cookieconsent plugin
var cc = initCookieConsent();

// example logo
var logo = '<img src="https://www.axeinternet.com/images/logo-axeinternet.png" alt="Axeinternet" loading="lazy" style="margin-left: -4px; margin-bottom: -5px; height: 35px">';
var cookie = '🍪';

// run plugin with config object
cc.run({
    current_lang : 'fr',
    autoclear_cookies : true,                   // default: false
    cookie_name: 'cc_cookie',             // default: 'cc_cookie'
    cookie_expiration : 182,                    // default: 182
    page_scripts: true,                         // default: false

    // auto_language: null,                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                           // default: true
    // delay: 0,                                // default: 0
    // force_consent: false,
    // hide_from_bots: false,                   // default: false
    // remove_cookie_tables: false              // default: false
    // cookie_domain: location.hostname,        // default: current domain
    // cookie_path: "/",                        // default: root
    // cookie_same_site: "Lax",
    // use_rfc_cookie: false,                   // default: false
    // revision: 0,                             // default: 0

    gui_options: {
        consent_modal: {
            layout: 'box',                      // box,cloud,bar
            position: 'bottom',           // bottom,middle,top + left,right,center
            transition: 'slide'                 // zoom,slide
        },
        settings_modal: {
            layout: 'box',                      // box,bar
            // position: 'left',                // right,left (available only if bar layout selected)
            transition: 'slide'                 // zoom,slide
        }
    },

    onFirstAction: function(){
        console.log('onFirstAction fired');
    },

    onAccept: function (cookie) {
        console.log('onAccept fired ...');
    },

    onChange: function (cookie, changed_preferences) {
        console.log('onChange fired ...');
    },

    languages: {
        'fr': {
            consent_modal: {
                title: cookie + ' Utilisation des cookies',
                description: 'Bonjour, ce site utilise des cookies pour assurer son bon fonctionnement. <button type="button" data-cc="c-settings" class="cc-link">Laissez-moi choisir</button>',
                primary_btn: {
                    text: 'Tout accepter',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Tout refuser',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: logo,
                save_settings_btn: 'Enregistrer',
                accept_all_btn: 'Tout accepter',
                reject_all_btn: 'Tout refuser',
                close_btn_label: 'Fermer',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Expiration'},
                    {col4: 'Description'}
                ],
                blocks: [
                    {
                        title: 'Utilisation des Cookies',
                        description: 'Le site utilise des cookies pour assurer ses fonctionnalités de base.'
                    },{
                        title: 'Cookies strictement nécessaires',
                        description: 'Ces cookies sont indispensables au bon fonctionnement du site internet. Sans ces cookies, le site Web ne fonctionnerait pas correctement',
                        toggle: {
                            value: 'cookie',
                            enabled: true,
                            readonly: true
                        }
                    }, {
                        title: 'Cookies Performance and Analytics',
                        description: 'Ces cookies permettent au site Web de se souvenir des choix que vous avez faits dans le passé',
                        toggle: {
                            value: 'analytics',
                            enabled: false,
                            readonly: false
                        },
						cookie_table: [
							{
								col1: '-',
								col2: '-',
								col3: '-',
								col4: '-',
								is_regex: true
							}
						]
                    }, {
                        title: 'Cookies publicitaires et de ciblage',
                        description: 'Ces cookies collectent des informations sur la façon dont vous utilisez le site Web, les pages que vous avez visitées et les liens sur lesquels vous avez cliqué. Toutes les données sont anonymisées et ne peuvent pas être utilisées pour vous identifie',
                        toggle: {
                            value: 'targeting',
                            enabled: false,
                            readonly: false
                        },
						cookie_table: [
							{
								col1: '-',
								col2: '-',
								col3: '-',
								col4: '-',
								is_regex: true
							}
						]
                    }, {
                        title: 'Plus d\informations',
                        description: 'Pour toute question relative à la politique en matière de cookies et à vos choix, <a class="cc-link" target="_blank" href="https://www.cnil.fr/fr/site-web-cookies-et-autres-traceurs">Lire plus ...</a>.',
                    }
                ]
            }
        }
    }

});