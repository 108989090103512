
import Masonry from 'masonry-layout';

if($('#galleryMsnry').length){
    var $grid = document.querySelector('#galleryMsnry');
    var msnry = new Masonry($grid, {
        itemSelector: '.col',
    });
    var $images = $grid.querySelectorAll('#galleryMsnry a img');
    // $images.forEach(function (el) {
    //     el.addEventListener('load', function () {
    //         console.log("Image is loaded: " + el.getAttribute("src"));
    //         msnry.layout();
    //     });
    // });

    Promise.all(
        Array.from($images).filter(img => !img.complete)
            .map(img => new Promise(resolve => { 
                img.addEventListener('load', resolve); 
                img.addEventListener('error', resolve);
            })
            )
    ).then(
        () => {
            console.log('images finished loading');
            msnry.layout();
        }
    );
}

